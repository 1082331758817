import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/Layout";
import SEO from "../components/SEO";

class Pricing extends React.Component {

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    // }

    render() {
        const { data } = this.props;
        const introduction = data.introduction.nodes;
        const introduction_vi = data.introduction_vi.nodes;
        const choose_product_excel_sync = data.choose_product_excel_sync.nodes;
        const choose_product_excel_sync_vi = data.choose_product_excel_sync_vi.nodes;
        const choose_product_outlook = data.choose_product_outlook.nodes;
        const choose_product_outlook_vi = data.choose_product_outlook_vi.nodes;

        return (
            <Layout>
                <SEO title="Pricing" description="" meta={[{ name: `keywords`, content: ["", "", "", ""] }]} pathname="/pricing" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-pricing-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-pricing-introduction-detail">
                                <Row className="app-pricing-introduction-row-custom">
                                    <Col className="app-pricing-introduction-col-custom" xs={12} md={12} lg={5}>
                                        {introduction.map((res) => (
                                            <div className="app-data-en app-pricing-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {introduction_vi.map((res) => (
                                            <div className="app-data-vi app-pricing-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={7}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-choose-product" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">Choose Your Product</h2>
                                        <h2 className="app-data-vi">Choose Your Product</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-choose-product-detail">
                                <Row className="app-choose-product-row-custom">
                                    <Col className="app-choose-product-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-choose-product-category-wrap">
                                            <img src="/app-icon-pricing-excel-sync.svg" alt="app icon pricing excel sync" />
                                            <h2>eXsync</h2>
                                        </div>
                                        <div className="app-choose-product-detail-wrap">
                                            {choose_product_excel_sync.map((res) => (
                                                <div className="app-data-en app-choose-product-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                            {choose_product_excel_sync_vi.map((res) => (
                                                <div className="app-data-vi app-choose-product-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                        </div>
                                    </Col>
                                    <Col className="app-choose-product-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-choose-product-category-wrap">
                                            <img src="/app-icon-pricing-outlook.svg" alt="app icon pricing outlook" />
                                            <h2>Outlook</h2>
                                        </div>
                                        <div className="app-choose-product-detail-wrap">
                                            {choose_product_outlook.map((res) => (
                                                <div className="app-data-en app-choose-product-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                            {choose_product_outlook_vi.map((res) => (
                                                <div className="app-data-vi app-choose-product-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Pricing;

export const query = graphql`
    query PricingPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        choose_product_excel_sync: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_choose_product_excel_sync" } } }
            sort: { fields: frontmatter___publishedAt, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
        choose_product_excel_sync_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_choose_product_excel_sync_vi" } } }
            sort: { fields: frontmatter___publishedAt, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
        choose_product_outlook: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_choose_product_outlook" } } }
            sort: { fields: frontmatter___publishedAt, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
        choose_product_outlook_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "pricing_choose_product_outlook_vi" } } }
            sort: { fields: frontmatter___publishedAt, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;